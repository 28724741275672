/* Standard styles template for the entire website */


/* Importing fonts for the site */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

:root {
  --primary-dark: #000;
  --primary-light: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Merriweather", sans-serif;
}

ul{
  list-style-type: none
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1rem;
}

button {
  background-image: linear-gradient(45deg,var(--primary-dark) 0%, var(--primary-light) 100%);
  border: 1px solid var(--primary-dark);
  color: #fff;
  padding: .7rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Merriweather", sans-serif;
}

.linebreak {
  padding-top: 1rem;
  width: 100%;
}

.icon {
  cursor: pointer;
  color:#000;
}

