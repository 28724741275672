/* Styles adjustments for all image-text blocks on the home page and destinations page grids */

.selects-location img {
    width: 100%;
    height: 100%;
    z-index: -1;
}

.selects-location {
    position: relative;
}

.selects-location .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    cursor: pointer;
    z-index: 4;
}

.selects-location h3 {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    color: #fff;
    z-index: 5;
}

/* Additional adjustments for mobile screens */
@media screen and (max-width: 740px) {
    .selects-location h3 {
        font-size: 1rem;
    }
}


