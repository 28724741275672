.bio {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    background-color: #eb998b;
}


.bio-container {
    --gap: 2rem;
    --num-cols: 5;
    --row-height: 300px;

    display: grid;
    grid-template-columns: repeat(var(--num-cols),1fr);
    gap: var(--gap);
    margin: auto;
    padding: 1rem 0;
    color: transparent;
}

.bio img {
    width: 100%;
    border-radius:100%;
    object-fit: cover;
    margin: auto;
}

.img-span {
    grid-column: span 1;
}

.bio-text {
    margin-top: auto;
    margin-bottom: auto;
    grid-column: span 4;
    flex-direction: column;
    text-align: center;
    color: #000;
    font-family: 'Merriweather',sans-serif;
}

.bio-text p{
    font-size: 1.5rem;
}

/* adjustments for mobile displays */

@media screen and (max-width: 940px) {
    .bio-text {
        font-size:1.2rem;
    }
    .bio-container {
        --num-cols: 1;
        --row-height: 100px;
        --gap: 0rem; 
    }
    .bio img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: cover;
        padding-bottom: 2rem;
    }
}

