/* Style adjustments for individual gear item text and image blocks */

.gear-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2,0.5fr);
    grid-gap: 1rem;
    padding-bottom: 1rem;
}

.gear-items img {
    height: 300px;
    display: flex;
    justify-content: center;
    grid-row: span 2;
    grid-column: span 1;
}

.span-2-col {
    grid-column: span 2;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.gear-grid li {
    padding-top: none;
    font-size: 1rem;
}


/* Additional adjustments for mobile displays*/
@media screen and (max-width: 740px) {


    .gear-grid {
        display: block;
    }

    .gear-grid img {
        margin-left: auto;
        margin-right: auto;
        height: 200px
    }

}


