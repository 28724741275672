/* Style adjustments for the Blog page */
.dest-body h1{
    position: flex;
    text-align: center;
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.dest-body p1 {
    position: flex;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
}

.dest-images .container-grid {
    display: grid;
    padding-top: 3rem;
    padding-bottom: 3rem;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
}

.dest-images .container-grid-2 {
    display: grid;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 4rem;
}
/* Adjustments for mobile screens*/
@media screen and (max-width: 740px) {
    .dest-images .container-grid {
        grid-template-columns: repeat(1,1fr);
    }
    .dest-images .container-grid-2 {
        grid-template-columns: repeat(1,1fr);
        padding-left: 0rem;
        padding-right: 0rem;
        grid-gap: 1rem;
    }
   
}