/* Style adjustments for the standard image template for each page except the home page*/

.standard img {
    width: 100%;
    height: 500px;
    z-index: -1;
    object-fit: cover;
}

.standard {
    position: relative;
}

.standard .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.standard p {
    position: relative;
    top: 250px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-size: 5rem;
    font-weight: 600;
    z-index: 2;
}

/* Additional adjustments for mobile screens */
@media screen and (max-width: 940px) {
    .standard p {
        font-size: 3rem;
        font-weight: 400;
    }
    
}