/* Style adjustments for the credit card description items */

.finances-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-gap: 1rem;
    grid-area: 280px 100%;
    padding-bottom: 1rem;
}

.finances-items img {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    grid-row: span 2;
    grid-column: span 1;
    height: 200px;
}

.finances-items h4 {
    padding-top: 0rem;
}

.span-2-col {
    grid-column: span 2;
    padding-top: 0rem;
}

.finances-items li {
    font-size: 1rem;
}


/* Additional adjustments for mobile screens*/
@media screen and (max-width: 740px) {
    .finances-grid {
        display: block;
    }
    .finances-items img {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 1rem;
    }

    .finances-items h4 {
        padding-top: 1rem;
    }
}