/* Style adjustments for the Gear page */

.gear-content h2 {
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.gear-content h3 {
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.gear-content h4 {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

    



