/* Style adjustments for the Finances page */
.finances-content h2 {
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.finances-content h3 {
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.finances-content h4 {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.other-cards {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-bottom: 1.5rem;
}

.review {
    width: 100%;
    display:inline-table;
    margin-bottom: 1rem;
}

.review p {
    height: 100%;
}

.review img {
    height: 300px;
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

/* Additional adjustments for mobile screens */

@media screen and (max-width: 740px) {
    
    .review {
        height: 100%;
    }

    .review img {
        max-height: 400px;
        display:flex;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
    .other-cards {
        display: block;
    }

}