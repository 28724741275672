/* Styles adjustments for the image grid on the home page */
.selects h1{
    position: flex;
    text-align: center;
    padding-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.selects .container {
    display: grid;
    padding-top: 3rem;
    padding-bottom: 3rem;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
}

/* Additional adjustments for mobile screens */
@media screen and (max-width: 740px) {
    .selects .container {
        grid-template-columns: repeat(1,1fr);
    }
}