/* Style adjustments for the navigation bar on desktops */
.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    z-index: 2;
    position: absolute;
}

.logo h2{
    color:#000;
}
.navbar-bg {
    background-color: transparent;
}

.nav-menu {
    display: flex;
    width: 40%
}

.nav-icons .social-icons .icon{
    margin: 1rem;
}

li {
    padding: 0 1.5rem;
    font-size: 1.2rem;
    color: #000;
    cursor: pointer;
}

.hamburger {
    display: none;
}

.mobile-menu {
    display: none;
}


.overlay {
    background-color: rgba(246, 241, 241, 0.7);
    position: absolute;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: -1;
}

/* Styles adjustments for the navigation menu on mobile displays */
 @media screen and (max-width: 940px) {
    .logo h2{
        font-size: 2rem;
    }
    .nav-menu {
        display: none;
    }

    .nav-icons {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        top: -100vh;
        left: 0;
        position: absolute;
        justify-content: space-between;
        background-color: rgba(246, 241, 241, 0.7);        
        transition: 1s;
        z-index: 3;
    }

    .active {
        top: 80px;
    }

    .mobile-menu li {
        padding: 1.2rem 1rem;
        margin: 0 1rem;
        border-bottom: 1px solid #ccc;
    }

    .mobile-nav {
        margin: 1rem 0;
    }

    .mobile-menu-bottom {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }

    .mobile-menu button {
        margin: 2.5rem;
        width: 90%;
    }

    .social-icons {
        display: flex;
        justify-content: space-around;
        padding: 1rem 0;
    }

    .social-icons .icon:hover {
        transform: scale(1.5);
        transition: 0.3s;
    }
    
 }