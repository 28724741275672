/* Style adjustments for all location pages */

.all-text {
    padding-top: 1rem;
}

.all-text p {
    padding-bottom: 1rem;
}

.all-text h4 {
    padding-bottom:1rem;
}

.image-text {
    padding-top: 1rem;
    width: 100%;
}

.image-text img {
    height: 270px;
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.all-images {
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}
.all-images img {
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    height: 270px;
    max-width: 380px;
}

.bullets {
    padding-top: 1rem;
    width: 100%;
    padding-bottom: 1rem;
}

.bullets li {
    font-size: 1rem;
}


/* Additional adjustments for mobile screens */
@media screen and (max-width: 740px) {
    h3 {
        text-align: center;
        
    }
    .image-text {
        grid-template-columns: repeat(1,1fr);
    }

    .image-text img {
        float: inline-start;
    }

    .all-images {
        grid-template-columns: repeat(1,1fr);
    }


}