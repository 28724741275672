/* Style adjustments for the background image of the homge page */

.hero {
    width: 100%;
    height: 100vh;
    color: #fff;
    position: relative;
}

.hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero .content {
    position: absolute;
    top: 170px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 1rem;
    z-index: 1;
    color: #fff;
}

.hero .content h1 {
    font-size: 5rem;
    font-weight: 600;
    padding-bottom: 20px;
}

/* Additional adjustments for mobile displays */
@media screen and (max-width: 940px) {
    .hero .content h1 {
        font-size: 3rem;
        font-weight: 600;
        padding-bottom: 20px;
    }
    
}

